import { DescriptionsItemType } from 'antd/es/descriptions';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import AudioUrlCell from '@marrlab-app-shared/components/audioUrlCell';

import { formatDate } from '../../../../utils/date/date';
import { formatDuration } from '../../../../utils/date/time';
import { CallHistory } from '../../../../api/callsHistory/types';
import { getPreSignedAudioUrl } from '../../../../api/callsHistory';

interface CellRendererProps {
  column: DescriptionsItemType;
  data?: CallHistory | null;
}

const CellRenderer = ({ column, data }: CellRendererProps) => {
  if (!data) {
    return '';
  }

  if (column.key === 'startDate') {
    return formatDate(data.startDate as unknown as string, true);
  }

  if (column.key === 'callDurationSec') {
    return formatDuration(data.callDurationSec ?? undefined);
  }

  if (column.key === 'audioUrl') {
    return (
      <AudioUrlCell
        value={data.audioUrl ?? undefined}
        callId={data.callLogId}
        getPreSignedAudioUrl={getPreSignedAudioUrl}
      />
    );
  }

  if (column.key === 'campaign') {
    return data.orchestratorProvider?.campaign?.displayName;
  }

  if (column.key === 'customer') {
    return data.orchestratorProvider?.campaign?.customer?.name;
  }

  if (column.key === 'retellCallId') {
    return data.retellCallAttributes?.call_id;
  }

  if (column.key === 'retellAgentId') {
    return data.retellCallAttributes?.agent_id;
  }

  if (column.key === 'audioProtocol') {
    return data.retellCallAttributes?.audio_websocket_protocol;
  }

  if (column.key === 'callCompletion') {
    const callAnalysis = data.retellCallAttributes?.call_analysis;

    if (!callAnalysis) {
      return null;
    }

    return (
      <Tooltip
        title={callAnalysis?.call_completion_rating_reason}
      >
        {callAnalysis?.call_completion_rating}
        {' '}
        <QuestionCircleOutlined className="call-params-hint-icon" />
      </Tooltip>
    );
  }

  if (column.key === 'taskCompletion') {
    const callAnalysis = data.retellCallAttributes?.call_analysis;

    if (!callAnalysis) {
      return null;
    }

    return (
      <Tooltip
        title={callAnalysis?.agent_task_completion_rating_reason}
      >
        {callAnalysis?.agent_task_completion_rating}
        {' '}
        <QuestionCircleOutlined className="call-params-hint-icon" />
      </Tooltip>
    );
  }

  if (column.key === 'userSentiment') {
    return data.retellCallAttributes?.call_analysis?.user_sentiment;
  }

  if (column.key === 'disconnectionReason') {
    return data.retellCallAttributes?.disconnection_reason;
  }

  if (column.key === 'averageEndToEndLatency') {
    const e2eLatency = data.retellCallAttributes?.e2e_latency;
    const medianE2eLatency = e2eLatency?.p50 ? `${Math.round(e2eLatency.p50)} ms` : '';
    const minE2eLatency = e2eLatency?.min ? `${Math.round(e2eLatency.min)} ms` : '';

    const llmLatency = data.retellCallAttributes?.llm_latency;
    const medianLlmLatency = llmLatency?.p50 ? `${Math.round(llmLatency.p50)} ms` : '';
    const minLlmLatency = llmLatency?.min ? `${Math.round(llmLatency.min)} ms` : '';

    if (!data.retellCallAttributes?.e2e_latency) {
      return null;
    }

    return (
      <Tooltip
        title={(
          <>
            End to end latency:
            <br />
            median:
            {' '}
            {medianE2eLatency}
            <br />
            min:
            {' '}
            {minE2eLatency}
            <br />
            <br />
            LLM latency:
            <br />
            median:
            {' '}
            {medianLlmLatency}
            <br />
            min:
            {' '}
            {minLlmLatency}
          </>
        )}
      >
        {medianE2eLatency}
        {' '}
        <QuestionCircleOutlined className="call-params-hint-icon" />
      </Tooltip>
    );
  }

  if (column.key === 'summary') {
    return data.retellCallAttributes?.call_analysis?.call_summary;
  }

  return <div>{data[column.key as keyof CallHistory] as string}</div>;
};

export default CellRenderer;
