import { useCallback, useState, MouseEventHandler } from 'react';
import { TableColumnsType, TableProps } from 'antd';

import { CallHistory } from '../../../api/callsHistory/types';
import { columnsInitialState } from '../constants/columnsInitialState';
import useNavigate from '../../../hooks/navigation/useNavigate';
import { addDefaultSortToColumns } from '../utils/mappers';

const sticky: TableProps<CallHistory>['sticky'] = { offsetHeader: 0 };

const scroll: TableProps<CallHistory>['scroll'] = { x: 'max-content' };

const useTableConfiguration = () => {
  const { navigateToCallDetails } = useNavigate();

  const [columns, setColumns] = useState<TableColumnsType<CallHistory>>(() => (
    addDefaultSortToColumns({
      columns: columnsInitialState,
    })
  ));

  const onRow = useCallback((record: CallHistory) => ({
    onClick: ((event) => {
      if (!(event.target as Element).closest('td')) {
        return;
      }

      navigateToCallDetails(record.callLogId, true);
    }) as MouseEventHandler,
  }), [navigateToCallDetails]);

  return {
    columns,
    sticky,
    scroll,
    setColumns,
    onRow,
  };
};

export default useTableConfiguration;
