import { Tabs } from 'antd';
import { useCallback, useMemo } from 'react';

import CustomersProvider from '../customersProvider';
import { customerAllOption } from '../../constants';
import CustomersQueueSizeProvider from '../customersQueueSizeProvider';

const CustomerTabs = () => {
  const { customers } = CustomersProvider.useCustomers();
  const { currentCustomer, setCurrentCustomer } = CustomersProvider.useCurrentCustomer();
  const { queueSize } = CustomersQueueSizeProvider.useCustomersQueueSize();

  const options = useMemo(() => [
    {
      label: 'All',
      key: customerAllOption,
    },
    ...customers.map((customer) => {
      const size = queueSize[customer.customerId]?.size;

      return ({
        label: `${customer.name} ${size ? `(${size})` : ''}`,
        key: customer.customerId,
      });
    }).sort((a, b) => (
      (queueSize[b.key]?.size ?? 0) - (queueSize[a.key]?.size ?? 0)
    )),
  ], [customers, queueSize]);

  const onChange = useCallback((value: string) => {
    setCurrentCustomer(value);
  }, [setCurrentCustomer]);

  return (
    <Tabs
      size="large"
      activeKey={currentCustomer}
      items={options}
      onChange={onChange}
    />
  );
};

export default CustomerTabs;
