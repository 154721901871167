import {
  Checkbox,
  Col,
  Form,
  Row,
  Switch,
  Typography,
} from 'antd';
import { useCallback } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { CheckboxRowProps } from '../../types';

const CheckboxRow = ({
  withAfter,
  propName,
  title,
  duringValue,
  afterValue,
  resultValue,
  resultError,
  prefer,
  disabled,
  onUpdate: onUpdateBase,
  onPreferUpdate: onPreferUpdateBase,
}: CheckboxRowProps) => {
  const onUpdate = useCallback((event: CheckboxChangeEvent) => {
    onUpdateBase({
      propName,
      value: event.target.checked,
    });
  }, [onUpdateBase, propName]);

  const onPreferUpdate = useCallback((checked: boolean) => {
    onPreferUpdateBase({
      propName,
      value: checked ? 'after' : 'during',
    });
  }, [onPreferUpdateBase, propName]);

  return (
    <Row>
      <Col span={5}><Typography.Text strong>{title}</Typography.Text></Col>
      <Col span={5}>
        <Checkbox checked={duringValue} disabled />
      </Col>
      <Col span={5}>
        {withAfter && (
          <Checkbox checked={afterValue} disabled />
        )}
      </Col>
      <Col span={4}>
        <Switch checked={prefer === 'after'} disabled={disabled} onChange={onPreferUpdate} />
      </Col>
      <Col span={5}>
        <Form.Item
          className="mb-0"
          validateStatus={resultError ? 'error' : undefined}
          help={resultError}
        >
          <Checkbox
            disabled={disabled}
            checked={resultValue}
            onChange={onUpdate}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CheckboxRow;
