import { ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';
import { Flex, Typography } from 'antd';
import Icon, { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';

import { CurrentlyPlaying } from '../../types';
import { formatAudioTime } from '../../utils/duration';

import AudioStop from '../../../../assets/icons/audio_stop.svg?react';

import './index.scss';

interface PlayerProps {
  progressBarRef: ForwardedRef<HTMLInputElement>;
  playing: boolean;
  duration: number;
  currentTime: number;
  currentlyPlaying: CurrentlyPlaying | null;
  play: () => void;
  pause: () => void;
  stop: () => void;
  onEnded: () => void;
  onLoadedMetadata: () => void;
  onProgressChange: () => void;
}

const Player = (({
  progressBarRef,
  playing,
  duration,
  currentTime,
  currentlyPlaying,
  play,
  pause,
  stop,
  onEnded,
  onLoadedMetadata,
  onProgressChange,
}: PlayerProps, ref: ForwardedRef<HTMLAudioElement>) => (
  <Flex align="center" className={cn('audio-player-fixed-footer', { open: !!currentlyPlaying })}>
    <audio
      className="native-audio-tag"
      ref={ref}
      src={currentlyPlaying?.url}
      controls
      onEnded={onEnded}
      onLoadedMetadata={onLoadedMetadata}
    />
    {playing ? (
      <PauseOutlined className="icon-control pause-control" onClick={pause} />
    ) : (
      <CaretRightOutlined className="icon-control" onClick={play} />
    )}
    <Icon component={AudioStop} className="icon-control stop-control" onClick={stop} />
    <Typography.Text className="duration">
      {formatAudioTime(currentTime)}
    </Typography.Text>
    <input
      type="range"
      step="0.01"
      className="progress-bar"
      ref={progressBarRef}
      onChange={onProgressChange}
    />
    <Typography.Text className="duration">
      {formatAudioTime(duration)}
    </Typography.Text>
  </Flex>
));

const PlayerWithRef = forwardRef<HTMLAudioElement, PlayerProps>(Player);

export default PlayerWithRef;
