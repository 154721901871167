import { useCallback, useState } from 'react';
import { PaginationProps } from 'antd/es/pagination/Pagination';
import { getDefaultPagination } from '../utils/searchParams';

const usePagination = () => {
  const defaultPagination = getDefaultPagination();

  const [page, setPage] = useState(defaultPagination.page);
  const [pageSize, setPageSize] = useState(defaultPagination.pageSize);

  const onPageChange: PaginationProps['onChange'] = useCallback((localPage: number, localPageSize: number) => {
    setPage(localPage);
    setPageSize(localPageSize);
  }, []);

  const resetPage = useCallback(() => {
    setPage(1);
  }, []);

  const showTotal: PaginationProps['showTotal'] = useCallback((
    total: number,
    range: [number, number],
  ) => `${range[0]}-${range[1]} of ${total} items`, []);

  const skip = pageSize * (page - 1);

  return {
    page,
    skip,
    pageSize,
    showTotal,
    resetPage,
    onPageChange,
  };
};

export default usePagination;
