import { ChangeEvent, useCallback } from 'react';
import { Input, Select, Tooltip } from 'antd';

import { FilterProps } from '../../types';

import './index.scss';

interface SearchProps {
  searchBy?: string;
  updateSearch: (search: string) => void;
  defaultSearch?: string;
  onFiltersUpdate: FilterProps['onFiltersUpdate'];
}

const searchByOptions = [
  {
    value: 'callLogId',
    label: 'Call log ID',
  },
  {
    value: 'callLogUuid',
    label: 'Call log UUID',
  },
  {
    value: 'operatorNumber',
    label: 'Operator number',
  },
  {
    value: 'agentNumber',
    label: 'Agent number',
  },
  {
    value: 'userNumber',
    label: 'User number',
  },
  {
    value: 'campaign',
    label: 'Campaign name',
  },
  {
    value: 'customer',
    label: 'Customer name',
  },
];

const Search = ({
  searchBy,
  defaultSearch,
  updateSearch,
  onFiltersUpdate,
}: SearchProps) => {
  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    updateSearch(event.target.value);
  }, [updateSearch]);

  const onSearchByChange = useCallback((value: string | null) => {
    onFiltersUpdate('searchBy', value ?? undefined);
  }, [onFiltersUpdate]);

  return (
    <Input.Search
      addonBefore={(
        <Tooltip
          placement="top"
          title='Select a specific column to search by it ("includes" creteria)'
        >
          <Select
            className="review-queue-search-by-select"
            options={searchByOptions}
            value={searchBy}
            onChange={onSearchByChange}
          />
        </Tooltip>
      )}
      className="w-prose"
      placeholder="Type to search"
      allowClear
      defaultValue={defaultSearch}
      onChange={onSearchChange}
    />
  );
};

export default Search;
