import { useCallback, useMemo } from 'react';
import {
  Empty,
  Flex,
  Select,
  Spin,
  Typography,
} from 'antd';
import usePaginatedDataFetchWithSearch from '@marrlab-app-shared/hooks/data/usePaginatedDataFetchWithSearch';

import { FilterProps } from '../../types';

import { getCampaignsRequest } from '../../../../api/callsHistory';
import { emptyFilterValue } from '../../constants/columnsInitialState';

const CampaignsFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const {
    updateSearch,
    loading,
    data,
  } = usePaginatedDataFetchWithSearch({ request: getCampaignsRequest });

  const onChange = useCallback((value?: string) => {
    onFiltersUpdate('campaign', value);
  }, [onFiltersUpdate]);

  const options = useMemo(() => {
    const items = data?.map((item) => ({
      value: item.campaignId,
      label: item.displayName,
    })) ?? [];

    items.push({
      value: emptyFilterValue,
      label: 'Empty',
    });

    return items;
  }, [data]);

  return (
    <Flex vertical gap={8} className="min-w-48">
      <Typography.Text>Campaign</Typography.Text>
      <Select
        placeholder="Any campaign"
        value={filters.campaign}
        onChange={onChange}
        onSearch={updateSearch}
        loading={loading}
        options={options}
        allowClear
        showSearch
        filterOption={false}
        notFoundContent={loading ? (
          <Flex justify="center" align="center" className="h-20">
            <Spin />
          </Flex>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      />
    </Flex>
  );
};

export default CampaignsFilter;
