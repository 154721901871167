import { useCallback, useMemo } from 'react';
import {
  Card,
  Dropdown,
  Empty,
  Flex,
  Spin,
  Typography,
} from 'antd';

import Message from './components/message';
import { parseTranscript } from '../../utils/transcript';
import { copyToClipboard, formatTranscriptForCopy } from './utils/copy';
import { GetPresignedUrlRequest } from '../../types';

import './index.scss';

interface CallTranscriptProps {
  loading?: boolean;
  data?: string[] | string | null;
  title?: string;
  withAudio?: boolean;
  audioUrl: string | null;
  getPreSignedUrlRequest: GetPresignedUrlRequest;
}

const copyButtonItems = [
  {
    key: 'copy-raw',
    label: 'Copy unformatted transcript',
  },
  {
    key: 'copy-json',
    label: 'Copy transcript json',
  },
];

const CallTranscript = ({
  loading,
  withAudio = true,
  data,
  title,
  audioUrl,
  getPreSignedUrlRequest,
}: CallTranscriptProps) => {
  const parsedTranscript = useMemo(() => parseTranscript(data), [data]);

  const onCopyButtonClick = useCallback(({ key }: { key?: string; }) => {
    if (key === 'copy-json') {
      copyToClipboard(JSON.stringify(parsedTranscript));
      return;
    }

    if (key === 'copy-raw') {
      copyToClipboard(formatTranscriptForCopy(parsedTranscript));
      return;
    }

    copyToClipboard(formatTranscriptForCopy(parsedTranscript, { markdown: true }));
  }, [parsedTranscript]);

  return (
    <Card
      title={(
        <Flex align="center" justify="space-between">
          <Typography.Title
            level={4}
            className="call-annotation-title"
          >
            {title ?? 'Transcript'}
          </Typography.Title>
          <Dropdown.Button
            type="primary"
            className="w-auto"
            menu={{
              items: copyButtonItems,
              onClick: onCopyButtonClick,
            }}
            onClick={() => onCopyButtonClick({})}
          >
            Copy transcript
          </Dropdown.Button>
        </Flex>
      )}
      className="bg-white rounded shadow-lg mb-6"
    >
      <Flex gap={10} vertical className="transcript-messages-list">
        {loading && (
          <Spin className="m-auto" size="large" />
        )}
        {!loading && !parsedTranscript?.length && (
          <Empty className="m-auto" />
        )}
        {!loading && !!parsedTranscript?.length && (
          parsedTranscript.map(({ role, content, words }, index) => (
            <Message
              // eslint-disable-next-line react/no-array-index-key
              key={`${role}-${content}-${index}`}
              withAudio={withAudio}
              role={role}
              content={content}
              audioUrl={audioUrl}
              words={words}
              index={index}
              prevMessage={parsedTranscript[index - 1]}
              getPreSignedUrlRequest={getPreSignedUrlRequest}
            />
          )))}
      </Flex>
    </Card>
  );
};

export default CallTranscript;
