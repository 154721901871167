import {
  Button,
  Descriptions,
  List,
  Rate,
  Tooltip,
  Typography,
} from 'antd';
import { EditFilled } from '@ant-design/icons';
import { useUser } from '@clerk/clerk-react';

import { formatDate } from '../../../../utils/date/date';
import { CallAnnotation } from '../../../../types';

import './index.scss';

interface AnnotationsListItemProps {
  annotation: CallAnnotation;
  onEdit: (annotation: CallAnnotation) => void;
}

const AnnotationsListItem = ({
  annotation,
  onEdit,
}: AnnotationsListItemProps) => {
  const { user } = useUser();

  return (
    <List.Item
      actions={[
        <Tooltip key="annotations-list-item-edit" title="Edit annotation">
          <Button
            type="primary"
            ghost
            size="small"
            icon={<EditFilled />}
            onClick={() => onEdit(annotation)}
            disabled={annotation.annotatorEmail !== user?.primaryEmailAddress?.emailAddress}
          />
        </Tooltip>,
      ]}
    >
      <List.Item.Meta
        title={annotation.annotatorName ?? annotation.annotatorEmail}
        description={(
          <>
            <Typography.Text className="annotations-list-item-create-date">
              {formatDate(annotation.created)}
            </Typography.Text>
            <Descriptions
              column={2}
              items={[
                {
                  key: 'taskCompleted',
                  label: 'Task completed',
                  children: annotation.taskCompleted ? 'Yes' : 'No',
                },
                {
                  key: 'humanLikeRating',
                  label: 'Human like rating',
                  children: <Rate value={annotation.humanLikeRating} disabled />,
                },
                {
                  key: 'callOutcome',
                  label: 'Call outcome',
                  children: annotation.callOutcome,
                  span: 2,
                },
                {
                  key: 'callQualityProblems',
                  label: 'Call quality problems to address',
                  children: annotation.callQualityProblems,
                  span: 2,
                },
              ]}
            />
          </>
        )}
      />
    </List.Item>
  );
};

export default AnnotationsListItem;
