import { useMemo } from 'react';
import { DescriptionsProps } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';

import CellRenderer from '../components/cellRenderer';
import { CallHistory } from '../../../api/callsHistory/types';
import {
  initialCommonColumns,
  initialRetellColumns,
} from '../constants';

interface UseCallDataArgs {
  data?: CallHistory | null;
}

interface Columns {
  commonColumns: DescriptionsProps['items'];
  retellColumns: DescriptionsProps['items'];
}

const useColumns = ({ data }: UseCallDataArgs): Columns => {
  const columns: Columns = useMemo(() => {
    const columnMapper = (column: DescriptionsItemType) => ({
      ...column,
      children: (
        <CellRenderer
          data={data}
          column={column}
        />
      ),
    });

    const commonColumns = initialCommonColumns.map(columnMapper);
    const retellColumns = initialRetellColumns.map(columnMapper);

    return {
      commonColumns,
      retellColumns,
    };
  }, [data]);

  return columns;
};

export default useColumns;
