import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';

import { Customer } from '../../../api/common/types';
import { getCustomersRequest } from '../../../api/callsHistory';

const useCustomersList = () => {
  const [data, setData] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getCustomersRequest({
        search: '',
        take: 100,
      });

      setData(response.data.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
  };
};

export default useCustomersList;
