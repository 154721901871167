import { useUser } from '@clerk/clerk-react';
import { useCallback } from 'react';

import { UserPermissions } from '../../constants/permissions';

interface Metadata {
  permissions: string[];
}

export const useCheckRole = () => {
  const { user } = useUser();

  const has = useCallback((permission: UserPermissions) => {
    const metadata: Metadata = user?.publicMetadata as unknown as Metadata;

    if (!metadata) {
      return false;
    }

    return metadata?.permissions?.includes?.(permission);
  }, [user]);

  return { has };
};

export default useCheckRole;
