import { useCallback, useRef, useState } from 'react';

import { CurrentlyPlaying, TimeRange } from '../types';

const useAudioPlayerState = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const playAnimationRef = useRef<number | null>(null);
  const progressBarRef = useRef<HTMLInputElement>(null);
  const isRewoundManually = useRef(false);

  const [currentlyPlaying, setCurrentlyPlaying] = useState<CurrentlyPlaying | null>(null);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [timeRange, setTimeRange] = useState<TimeRange | null>();

  const repeat = useCallback((innerTimeRange: TimeRange) => {
    if (audioRef.current && progressBarRef.current) {
      setCurrentTime(audioRef.current.currentTime);

      progressBarRef.current.value = audioRef.current.currentTime.toString();
      progressBarRef.current.style.setProperty(
        '--range-progress',
        `${(parseFloat(progressBarRef.current.value) / audioRef.current.duration) * 100}%`,
      );

      if (!isRewoundManually.current
        && innerTimeRange?.from !== undefined && innerTimeRange?.to !== undefined) {
        if (audioRef.current.currentTime > innerTimeRange.to) {
          if (playAnimationRef.current) {
            cancelAnimationFrame(playAnimationRef.current);
          }

          audioRef.current.pause();
          setPlaying(false);

          audioRef.current.currentTime = innerTimeRange.from;
          setCurrentTime(innerTimeRange.from);

          return;
        }
      }

      playAnimationRef.current = requestAnimationFrame(() => (
        repeat({ from: innerTimeRange.from, to: innerTimeRange.to })
      ));
    }
  }, []);

  const open = useCallback((data: CurrentlyPlaying & TimeRange) => {
    setCurrentlyPlaying(data);

    setTimeout(() => {
      if (audioRef.current) {
        if (data.from !== undefined && data.to !== undefined) {
          setTimeRange({ from: data.from, to: data.to });

          audioRef.current.currentTime = data.from;
        } else {
          setTimeRange(null);
          audioRef.current.currentTime = 0;
        }

        audioRef.current.play()
          .catch((error) => console.error(error));

        setPlaying(true);
        isRewoundManually.current = false;

        playAnimationRef.current = requestAnimationFrame(() => (
          repeat({ from: data.from, to: data.to })
        ));
      }
    });
  }, [repeat]);

  const play = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.play()
        .catch((error) => console.error(error));

      setPlaying(true);

      playAnimationRef.current = requestAnimationFrame(() => (
        repeat({ from: timeRange?.from, to: timeRange?.to })
      ));
    }
  }, [timeRange, repeat]);

  const pause = useCallback(() => {
    if (audioRef.current) {
      setPlaying(false);
      audioRef.current.pause();
    }

    if (playAnimationRef.current) {
      cancelAnimationFrame(playAnimationRef.current);
    }
  }, []);

  const stop = useCallback(() => {
    setCurrentlyPlaying(null);

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setPlaying(false);
      setDuration(audioRef.current.duration);
    }

    if (playAnimationRef.current) {
      cancelAnimationFrame(playAnimationRef.current);
    }
  }, []);

  const onEnded = useCallback(() => {
    setPlaying(false);

    if (playAnimationRef.current) {
      cancelAnimationFrame(playAnimationRef.current);
    }
    if (progressBarRef.current && audioRef.current) {
      progressBarRef.current.value = audioRef.current.duration.toString();
      progressBarRef.current.style.setProperty('--range-progress', '100%');

      if (timeRange?.from !== undefined && timeRange?.to !== undefined) {
        audioRef.current.currentTime = timeRange.from;
      }
    }
  }, [timeRange]);

  const onLoadedMetadata = useCallback(() => {
    if (audioRef.current && progressBarRef.current) {
      setDuration(audioRef.current.duration);
      progressBarRef.current.max = audioRef.current.duration.toString();
    }
  }, []);

  const onProgressChange = useCallback(() => {
    if (audioRef.current && progressBarRef.current) {
      isRewoundManually.current = true;

      audioRef.current.currentTime = parseFloat(progressBarRef.current.value);
    }
  }, []);

  return {
    playing,
    duration,
    currentTime,
    audioRef,
    progressBarRef,
    currentlyPlaying,
    open,
    play,
    pause,
    stop,
    onEnded,
    onLoadedMetadata,
    onProgressChange,
  };
};

export default useAudioPlayerState;
