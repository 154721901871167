import { CallTranscript } from '../../../types';
import { formatMinutesDuration } from '../../../utils/date/time';

interface GetDelayArgs {
  role: string;
  words?: CallTranscript['words'];
  prevMessage?: CallTranscript;
}

export const getUserToAgentDelay = ({
  role,
  words,
  prevMessage,
}: GetDelayArgs) => {
  const isUser = role === 'user';

  if (isUser) {
    return '';
  }

  if (!prevMessage?.words?.length || !words?.length) {
    return '';
  }

  const prevEnd = prevMessage.words[prevMessage.words.length - 1].end;
  const currentStart = words[0].start;

  return `(Delay: ${formatMinutesDuration(currentStart - prevEnd)})`;
};

export default getUserToAgentDelay;
