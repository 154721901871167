import {
  Col,
  Empty,
  Row,
  Typography,
} from 'antd';

import './index.scss';

interface HeaderProps {
  withAfter: boolean;
}

const Header = ({ withAfter }: HeaderProps) => (
  <Row>
    <Col span={5} />
    <Col span={5}><Typography.Text strong>During</Typography.Text></Col>
    <Col span={5}>
      <Typography.Text strong>After</Typography.Text>
      {!withAfter && (
        <Empty
          className="call-review-after-empty-state"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </Col>
    <Col span={4}><Typography.Text strong>During / After</Typography.Text></Col>
    <Col span={5}><Typography.Text strong>Result</Typography.Text></Col>
  </Row>
);

export default Header;
