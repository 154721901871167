import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';

import useSearchDebounce from '../state/useSearchDebounce';
import { Page } from '../../types';

export interface GetSearchableDataParams {
  search: string;
}

interface UsePaginatedDataFetchWithSearchArgs<Data> {
  request: (params: GetSearchableDataParams) => Promise<AxiosResponse<Page<Data>>> | Promise<void>;
}

const usePaginatedDataFetchWithSearch = <Data>({
  request,
}: UsePaginatedDataFetchWithSearchArgs<Data>) => {
  const [data, setData] = useState<Data[] | null>(null);
  const [loading, setLoading] = useState(false);

  const { search, updateSearch } = useSearchDebounce();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await request({ search });

      if (response) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [request, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    updateSearch,
    fetchData,
  };
};

export default usePaginatedDataFetchWithSearch;
