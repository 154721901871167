import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';

interface UseDataFetchArgs<Data> {
  request: () => Promise<AxiosResponse<Data>> | Promise<void>;
  showError?: boolean;
}

const useDataFetch = <Data>({
  request,
  showError = true,
}: UseDataFetchArgs<Data>) => {
  const [data, setData] = useState<Data | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await request();

      if (response) {
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
      if (showError && error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [request, showError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    fetchData,
  };
};

export default useDataFetch;
