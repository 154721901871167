import { PropsWithChildren } from 'react';
import { ClerkProvider as ClerkProviderBase } from '@clerk/clerk-react';
import { Outlet, useNavigate } from 'react-router-dom';

interface ClerkProviderProps extends PropsWithChildren {
  publishableKey: string;
  allowedRedirectOrigins: string[];
}

const ClerkProvider = ({
  children,
  publishableKey,
  allowedRedirectOrigins,
}: ClerkProviderProps) => {
  const navigate = useNavigate();

  return (
    <ClerkProviderBase
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}
      publishableKey={publishableKey}
      afterSignOutUrl="/sign-in"
      allowedRedirectOrigins={allowedRedirectOrigins}
    >
      {children}
      <Outlet />
    </ClerkProviderBase>
  );
};

export default ClerkProvider;
