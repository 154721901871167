import { Outlet } from 'react-router-dom';
import PageLayoutBase from '@marrlab-app-shared/components/pageLayout';

import config from '../../utils/config';

const UnauthorizedPageLayout = () => (
  <PageLayoutBase
    mode={config.MODE}
    title="Call operator"
  >
    <Outlet />
  </PageLayoutBase>
);

export default UnauthorizedPageLayout;
