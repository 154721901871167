import { useCallback, useState } from 'react';
import { CallInfo } from '../../../api/callsHistory/types';

type Errors = Partial<Record<keyof CallInfo, string>>;

const initialState: Errors = {};

const isSpokeValid = (state: Partial<CallInfo> | null) => {
  const noAnswer = state?.answered === 'answering_machine' || state?.answered === 'unanswered';

  return !(state?.spoke && noAnswer);
};

const isCustomerStatusValid = (state: Partial<CallInfo> | null) => {
  if (state?.answered === 'answering_machine') {
    return state?.customer_status === 'not_applicable';
  }

  return true;
};

const useCallInfoValidation = () => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback((state: Partial<CallInfo> | null) => {
    const newState: Errors = {
      spoke: isSpokeValid(state) ? '' : '"Spoke", "No answer" and "Answering machine" are not compatible',
      answered: isSpokeValid(state) ? '' : '"Spoke", "No answer" and "Answering machine" are not compatible',
      customer_status: isCustomerStatusValid(state) ? '' : 'Only "Not applicable" can be selected if machine answered',
    };

    setErrors(newState);

    return Object.values(newState).every((field) => !field);
  }, []);

  const clearErrors = useCallback(() => {
    setErrors(initialState);
  }, []);

  return {
    errors,
    isValid,
    clearErrors,
  };
};

export default useCallInfoValidation;
