import { CallTranscript } from '../../types';

export const parseTranscript = (rawText?: string[] | string | null): CallTranscript[] => {
  if (!rawText) {
    return [];
  }

  if (Array.isArray(rawText)) {
    return rawText.map((item) => JSON.parse(item));
  }

  const parsed = JSON.parse(rawText);

  if (typeof parsed[0] === 'string') {
    return parsed.map((item: string) => JSON.parse(item));
  }

  return parsed;
};

export default { parseTranscript };
