import {
  Col,
  Form,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import { useCallback } from 'react';
import { SelectRowProps } from '../../types';

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SelectRow = <ValueType, >({
  withAfter,
  propName,
  title,
  duringValue,
  afterValue,
  resultValue,
  resultError,
  prefer,
  disabled,
  options,
  onUpdate: onUpdateBase,
  onPreferUpdate: onPreferUpdateBase,
}: SelectRowProps<ValueType>) => {
  const onUpdate = useCallback((value: ValueType) => {
    onUpdateBase({
      propName,
      value: value as string,
    });
  }, [onUpdateBase, propName]);

  const onPreferUpdate = useCallback((checked: boolean) => {
    onPreferUpdateBase({
      propName,
      value: checked ? 'after' : 'during',
    });
  }, [onPreferUpdateBase, propName]);

  return (
    <Row>
      <Col span={5}><Typography.Text strong>{title}</Typography.Text></Col>
      <Col span={5}>
        <Select value={duringValue} disabled options={options} className="w-48" />
      </Col>
      <Col span={5}>
        {withAfter && (
          <Select value={afterValue} disabled options={options} className="w-48" />
        )}
      </Col>
      <Col span={4}>
        <Switch checked={prefer === 'after'} disabled={disabled} onChange={onPreferUpdate} />
      </Col>
      <Col span={5}>
        <Form.Item
          className="mb-0"
          validateStatus={resultError ? 'error' : undefined}
          help={resultError}
        >
          <Select
            className="w-48"
            disabled={disabled}
            value={resultValue}
            onChange={onUpdate}
            options={options}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SelectRow;
