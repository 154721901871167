import { useCallback } from 'react';
import useDataFetch from '@marrlab-app-shared/hooks/data/useDataFetch';

import { getCallUserInfo } from '../../../api/callsHistory';
import { CallInfo } from '../../../api/callsHistory/types';

interface UseCallDataArgs {
  callId?: string;
}

const useCallInfoData = ({ callId }: UseCallDataArgs) => {
  const request = useCallback(() => {
    if (callId) {
      return getCallUserInfo(callId);
    }

    throw new Error('No call id provided');
  }, [callId]);

  const { loading, data, fetchData } = useDataFetch<CallInfo[] | null>({ request });

  return {
    loading,
    data,
    refetch: fetchData,
  };
};

export default useCallInfoData;
