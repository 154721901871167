import { useMemo } from 'react';
import {
  Button,
  Card,
  Empty,
  List,
  Typography,
} from 'antd';
import { AxiosResponse } from 'axios';

import CallAnnotationModal from './components/callAnnotationModal';
import AnnotationsListItem from './components/annotationsListItem';
import useCallAnnotationModal from './hooks/useCallAnnotationModal';
import { CallAnnotation } from '../../types';

import './index.scss';

interface CallAnnotationsProps {
  loading: boolean;
  callId: number;
  annotations: CallAnnotation[];
  refetchCallData: () => void;
  createCallAnnotation: (annotation: CallAnnotation) => Promise<AxiosResponse<CallAnnotation>>;
  editCallAnnotation: (annotation: CallAnnotation) => Promise<AxiosResponse<CallAnnotation>>;
}

const CallAnnotations = ({
  loading,
  callId,
  annotations,
  refetchCallData,
  createCallAnnotation,
  editCallAnnotation,
}: CallAnnotationsProps) => {
  const callAnnotationModal = useCallAnnotationModal({ refetchCallData });

  const sortedAnnotations = useMemo(() => annotations.sort((a, b) => (
    new Date(b.created).getTime() - new Date(a.created).getTime()
  )), [annotations]);

  return (
    <Card
      title={(
        <Typography.Title
          level={4}
          className="call-annotation-title"
        >
          Call annotations
        </Typography.Title>
      )}
      className="bg-white rounded shadow-lg"
      extra={(
        <Button
          type="primary"
          onClick={() => callAnnotationModal.open(null)}
        >
          Add annotation
        </Button>
      )}
    >
      <List
        className="call-annotations-list"
        loading={loading}
        dataSource={sortedAnnotations}
        locale={{ emptyText: <Empty className="m-auto" /> }}
        renderItem={(annotation) => (
          <AnnotationsListItem
            annotation={annotation}
            onEdit={callAnnotationModal.open}
          />
        )}
      />
      <CallAnnotationModal
        isOpen={callAnnotationModal.isOpen}
        callId={callId}
        onConfirm={callAnnotationModal.onConfirm}
        onCancel={callAnnotationModal.onCancel}
        initialAnnotation={callAnnotationModal.initialAnnotation}
        createCallAnnotation={createCallAnnotation}
        editCallAnnotation={editCallAnnotation}
      />
    </Card>
  );
};

export default CallAnnotations;
