import { useCallback, MouseEvent } from 'react';
import {
  Button,
  Flex,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';

import { GetPresignedUrlRequest } from '../../types';
import { useAudioPlayer } from '../audioPlayer';
import usePreSignedAudioUrl from '../../hooks/data/usePreSignedAudioUrl';

interface AudioUrlCellProps {
  value?: string;
  callId?: number;
  getPreSignedAudioUrl: GetPresignedUrlRequest;
}

const AudioUrlCell = ({
  value,
  callId,
  getPreSignedAudioUrl,
}: AudioUrlCellProps) => {
  const { open, currentlyPlayingId } = useAudioPlayer();

  const { fetchData, loading } = usePreSignedAudioUrl({
    audioUrl: value,
    request: getPreSignedAudioUrl,
  });

  const onClick = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      const preSignedUrl = await fetchData();

      if (preSignedUrl) {
        window.open(preSignedUrl, '__blank');
      }
    },
    [fetchData],
  );

  const onPlayButtonClick = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      const preSignedUrl = await fetchData();

      if (preSignedUrl && callId) {
        open({ id: callId.toString(), url: preSignedUrl });
      }
    },
    [callId, fetchData, open],
  );

  if (!value) {
    return null;
  }

  return (
    <Flex gap={8} align="center">
      <Tooltip title="Play audio">
        <Button
          type="primary"
          ghost={currentlyPlayingId !== callId?.toString()}
          size="small"
          icon={<PlayCircleFilled />}
          onClick={onPlayButtonClick}
        />
      </Tooltip>
      <Typography.Link onClick={onClick}>Download</Typography.Link>
      {loading && <Spin />}
    </Flex>
  );
};

export default AudioUrlCell;
