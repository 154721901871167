import {
  Col,
  InputNumber,
  Row,
  Switch,
  Typography,
} from 'antd';
import { useCallback } from 'react';
import { CallParamRowProps } from '../../types';

const CallParamRow = ({
  withAfter,
  propName,
  title,
  duringValue,
  afterValue,
  resultValue,
  prefer,
  disabled,
  onUpdate: onUpdateBase,
  onPreferUpdate: onPreferUpdateBase,
}: CallParamRowProps) => {
  const onUpdate = useCallback((value: number | null) => {
    onUpdateBase({
      propName,
      value: value ?? undefined,
    });
  }, [onUpdateBase, propName]);

  const onPreferUpdate = useCallback((checked: boolean) => {
    onPreferUpdateBase({
      propName,
      value: checked ? 'after' : 'during',
    });
  }, [onPreferUpdateBase, propName]);

  return (
    <Row>
      <Col span={5}><Typography.Text strong>{title}</Typography.Text></Col>
      <Col span={5}>
        <InputNumber value={duringValue} disabled className="w-48" />
      </Col>
      <Col span={5}>
        {withAfter && (
          <InputNumber value={afterValue} disabled className="w-48" />
        )}
      </Col>
      <Col span={4}>
        <Switch checked={prefer === 'after'} disabled={disabled} onChange={onPreferUpdate} />
      </Col>
      <Col span={5}>
        <InputNumber
          min={0}
          className="w-48"
          disabled={disabled}
          value={resultValue as number | undefined}
          onChange={onUpdate}
        />
      </Col>
    </Row>
  );
};

export default CallParamRow;
