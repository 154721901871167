import { useCallback, useMemo, useState } from 'react';
import { debounce } from '../../utils/debounce';

interface UseSearchDebounceProps {
  onDebounce?: () => void;
  waitFor?: number;
  defaultSearch?: string;
}

const useSearchDebounce = ({
  onDebounce,
  waitFor = 300,
  defaultSearch,
}: UseSearchDebounceProps = {}) => {
  const [search, setSearch] = useState(defaultSearch ?? '');

  const debouncedSetSearch = useMemo(() => (
    debounce((searchString: string) => {
      if (onDebounce) {
        onDebounce();
      }
      setSearch(searchString);
    }, waitFor)
  ), [onDebounce, waitFor]);

  const updateSearch = useCallback((searchString: string) => {
    debouncedSetSearch(searchString);
  }, [debouncedSetSearch]);

  return {
    search,
    updateSearch,
  };
};

export default useSearchDebounce;
