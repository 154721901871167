import { TableColumnsType } from 'antd';
import { SortOrder as AntSortOrder } from 'antd/es/table/interface';
import { CallHistory } from '../../../api/callsHistory/types';
import { getDefaultSorting } from './searchParams';
import { SortOrder } from '../../../constants';

interface AddDefaultSortToColumnsArgs {
  columns: TableColumnsType<CallHistory>;
}

export const addDefaultSortToColumns = ({
  columns,
}: AddDefaultSortToColumnsArgs): TableColumnsType<CallHistory> => {
  const defaultSorting = getDefaultSorting();

  return columns.map((column) => {
    if (column.key === defaultSorting.sortBy) {
      return {
        ...column,
        sortOrder: (
          defaultSorting.order === SortOrder.ascend
            ? 'ascend'
            : 'descend'
        ) as AntSortOrder,
      };
    }

    return column;
  });
};

export default addDefaultSortToColumns;
