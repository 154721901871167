import {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
} from 'react';

import { CustomerQueueSize } from '../../api/common/types';
import useCustomersQueueSize from './hooks/useCustomersQueueSize';

type QueueSizeMap = Record<string, CustomerQueueSize>;

interface CustomersQueueSizeProviderArgs {
  queueSize: QueueSizeMap;
}

interface CustomersQueueSizeProviderActionsArgs {
  loading: boolean;
  refetch: () => void;
}

const CustomersQueueSizeContext = createContext<CustomersQueueSizeProviderArgs>({
  queueSize: {},
});

const CustomersQueueSizeActionsContext = createContext<CustomersQueueSizeProviderActionsArgs>({
  refetch: () => {
  },
  loading: false,
});

const CustomersQueueSizeProvider = ({ children }: PropsWithChildren) => {
  const { data: queueSize, loading, refetch } = useCustomersQueueSize();

  const value = useMemo(() => ({
    queueSize: queueSize.reduce((acc, customer) => {
      acc[customer.customerId] = customer;

      return acc;
    }, {} as QueueSizeMap),
  }), [queueSize]);

  const actionsValue = useMemo(() => ({
    refetch,
    loading,
  }), [loading, refetch]);

  return (
    <CustomersQueueSizeContext.Provider value={value}>
      <CustomersQueueSizeActionsContext.Provider value={actionsValue}>
        {children}
      </CustomersQueueSizeActionsContext.Provider>
    </CustomersQueueSizeContext.Provider>
  );
};

CustomersQueueSizeProvider.useCustomersQueueSize = () => useContext(CustomersQueueSizeContext);
CustomersQueueSizeProvider.useCustomersQueueSizeActions = () => (
  useContext(CustomersQueueSizeActionsContext)
);

export default CustomersQueueSizeProvider;
