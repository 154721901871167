import { useUser } from '@clerk/clerk-react';
import { useCallback, useEffect, useState } from 'react';

export const useOrganizationMemberships = () => {
  const { user } = useUser();

  const [showOrganizationSwitcher, setShowOrganizationSwitcher] = useState(false);

  const getOrganizationMemberships = useCallback(async () => {
    try {
      const response = await user?.getOrganizationMemberships();

      setShowOrganizationSwitcher((response?.total_count ?? 0) > 1);
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  useEffect(() => {
    getOrganizationMemberships();
  }, [getOrganizationMemberships]);

  return {
    showOrganizationSwitcher,
  };
};

export default useOrganizationMemberships;
