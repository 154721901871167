import { SignIn as SignInBase } from '@clerk/clerk-react';

import './index.scss';

const SignIn = () => (
  <div className="mx-auto mt-16 w-max">
    <SignInBase
      path="/sign-in"
    />
  </div>
);

export default SignIn;
