import { useCallback, useState } from 'react';

import useModalState from '../../../hooks/state/useModalState';
import { CallAnnotation } from '../../../types';

interface UseCallAnnotationModalArgs {
  refetchCallData: () => void;
}

const useCallAnnotationModal = ({ refetchCallData }: UseCallAnnotationModalArgs) => {
  const { isOpen, open: openBase, close } = useModalState();
  const [initialAnnotation, setInitialAnnotation] = useState<CallAnnotation | null>(null);

  const onConfirm = useCallback(() => {
    setInitialAnnotation(null);
    close();
    refetchCallData();
  }, [close, refetchCallData]);

  const onCancel = useCallback(() => {
    setInitialAnnotation(null);
    close();
  }, [close]);

  const open = useCallback((annotation: CallAnnotation | null) => {
    setInitialAnnotation(annotation);
    openBase();
  }, [openBase]);

  return {
    isOpen,
    initialAnnotation,
    open,
    onCancel,
    onConfirm,
  };
};

export default useCallAnnotationModal;
