import {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
  useState,
} from 'react';
import { Customer } from '../../api/common/types';
import useCustomersList from './hooks/useCustomersList';
import { customerAllOption } from '../../constants';
import { getDefaultCustomer } from '../../features/callsHistory/utils/searchParams';

interface CustomersProviderArgs {
  customers: Customer[];
  loading: boolean;
}

interface CurrentCustomerProviderArgs {
  currentCustomer: string;
  setCurrentCustomer: (currentCustomer: string) => void;
}

const CustomersContext = createContext<CustomersProviderArgs>({
  customers: [],
  loading: false,
});

const CurrentCustomerContext = createContext<CurrentCustomerProviderArgs>({
  currentCustomer: getDefaultCustomer().customer ?? customerAllOption,
  setCurrentCustomer: () => {
  },
});

const CustomersProvider = ({ children }: PropsWithChildren) => {
  const { data: customers, loading } = useCustomersList();

  const [currentCustomer, setCurrentCustomer] = useState<string>(
    getDefaultCustomer().customer ?? customerAllOption,
  );

  const customersValue = useMemo(() => ({
    customers,
    loading,
  }), [customers, loading]);

  const currentCustomerValue = useMemo(() => ({
    currentCustomer,
    setCurrentCustomer,
  }), [currentCustomer]);

  return (
    <CustomersContext.Provider value={customersValue}>
      <CurrentCustomerContext.Provider value={currentCustomerValue}>
        {children}
      </CurrentCustomerContext.Provider>
    </CustomersContext.Provider>
  );
};

CustomersProvider.useCustomers = () => useContext(CustomersContext);
CustomersProvider.useCurrentCustomer = () => useContext(CurrentCustomerContext);

export default CustomersProvider;
