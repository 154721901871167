import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useUser } from '@clerk/clerk-react';

import { CallAnnotation } from '../../../../../types';

interface UseCallAnnotationModalStateArgs {
  isOpen: boolean;
  callId: number;
  initialAnnotation: CallAnnotation | null;
  clearErrorForField: (propName: string) => void;
}

const useCallAnnotationModalState = ({
  isOpen,
  callId,
  initialAnnotation,
  clearErrorForField,
}: UseCallAnnotationModalStateArgs) => {
  const { user } = useUser();

  const initialState: CallAnnotation = useMemo(() => ({
    callAnnotationId: undefined,
    callLogId: callId.toString(),
    taskCompleted: true,
    annotatorEmail: user?.primaryEmailAddress?.emailAddress ?? '',
    annotatorName: user?.fullName ?? '',
    callOutcome: '',
    humanLikeRating: 3,
    callQualityProblems: '',
    created: '',
  }), [callId, user?.fullName, user?.primaryEmailAddress]);

  const [annotation, setAnnotation] = useState<CallAnnotation>(
    initialAnnotation ?? initialState,
  );

  const onAnnotationFieldUpdate = useCallback((
    propName: string,
    value: string | number | boolean,
  ) => {
    clearErrorForField(propName);
    setAnnotation((state) => ({
      ...state,
      [propName]: value,
    }));
  }, [clearErrorForField]);

  useEffect(() => {
    if (isOpen) {
      setAnnotation(initialAnnotation ?? initialState);
    }
  }, [initialAnnotation, initialState, isOpen]);

  return {
    editMode: !!initialAnnotation,
    annotation,
    onAnnotationFieldUpdate,
  };
};

export default useCallAnnotationModalState;
