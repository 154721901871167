import { useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useAuth } from '@clerk/clerk-react';
import useNavigate from '../navigation/useNavigate';

const useGlobalErrorHandling = () => {
  const { navigateToSignIn } = useNavigate();
  const { getToken } = useAuth();

  useEffect(() => {
    axios.interceptors.request.use(
      async (config) => {
        const { headers } = config;

        headers.Authorization = `Bearer ${await getToken()}`;

        return { ...config, headers };
      },
      (error) => Promise.reject(error),
    );

    axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          navigateToSignIn();
        }

        return Promise.reject(error);
      },
    );
  }, [getToken, navigateToSignIn]);
};

export default useGlobalErrorHandling;
