import {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
  useCallback,
  ReactNode,
} from 'react';
import { Button, notification, Space } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

interface OpenArgs {
  key: string;
  title: string;
  message: ReactNode;
  duration?: number;
  btn?: (api: NotificationInstance, key: string) => React.ReactNode;
}

interface SuccessNotificationContextArgs {
  open: (params: OpenArgs) => void;
  api?: NotificationInstance;
}

const SuccessNotificationContext = createContext<SuccessNotificationContextArgs>({
  open: () => {
  },
});

const SuccessNotificationProvider = ({ children }: PropsWithChildren) => {
  const [api, contextHolder] = notification.useNotification();

  const open = useCallback(({
    key: keyBase,
    title,
    message,
    btn,
    duration,
  }: OpenArgs) => {
    const key = `notification-${keyBase}`;

    api.success({
      message: title,
      description: message,
      btn: btn ? btn(api, key) : (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => api.destroy(key)}
          >
            Close
          </Button>
        </Space>
      ),
      key,
      duration: duration ?? 0,
    });
  }, [api]);

  const value = useMemo(() => ({
    open,
    api,
  }), [api, open]);

  return (
    <SuccessNotificationContext.Provider value={value}>
      {children}
      {contextHolder}
    </SuccessNotificationContext.Provider>
  );
};

SuccessNotificationProvider.useSuccessNotification = () => useContext(SuccessNotificationContext);

export default SuccessNotificationProvider;
