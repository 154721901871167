import { createSearchParams, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { URLSearchParamsInit } from 'react-router-dom/dist/dom';

import { GetCallsHistoryParams } from '../../../api/callsHistory/types';

export const useFiltersSearchParams = () => {
  const navigate = useNavigate();

  const updateSearchParams = useCallback((params: GetCallsHistoryParams) => {
    const newSearchParams: URLSearchParamsInit = {
      take: params.take.toString(),
      skip: params.skip.toString(),
      sortBy: params.sortBy,
      order: params.order,
    };

    if (params.search) {
      newSearchParams.search = params.search;
    }

    if (params.searchBy) {
      newSearchParams.searchBy = params.searchBy;
    }

    if (params.reviewStatus !== undefined) {
      newSearchParams.reviewStatus = params.reviewStatus.toString();
    }

    if (params.campaign !== undefined) {
      newSearchParams.campaign = params.campaign.toString();
    }

    if (params.customer !== undefined) {
      newSearchParams.customer = params.customer.toString();
    }

    navigate(`?${createSearchParams(newSearchParams)}`);
  }, [navigate]);

  return {
    updateSearchParams,
  };
};

export default useFiltersSearchParams;
