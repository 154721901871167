export const ReviewStatusNames = {
  toBeReviewed: 'To be reviewed',
  reviewed: 'Reviewed',
};

export enum SortOrder {
  ascend = 'ASC',
  descend = 'DESC',
}

export const customerAllOption = '-1';
