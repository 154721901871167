import { Col, Row, Typography } from 'antd';
import { CallInfo } from '../../../../api/callsHistory/types';

interface SourceDescriptionProps {
  sourceDescription?: CallInfo['source_description'];
}

const SourceDescription = ({ sourceDescription }: SourceDescriptionProps) => (
  <Row>
    <Col span={5}><Typography.Text strong>Source description</Typography.Text></Col>
    <Col span={5} />
    <Col span={5} />
    <Col span={4} />
    <Col span={5}><Typography.Text className="break-all">{sourceDescription}</Typography.Text></Col>
  </Row>
);

export default SourceDescription;
