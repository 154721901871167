import { Col, Row, Typography } from 'antd';
import { CallInfo } from '../../../../api/callsHistory/types';

interface CallSeqProps {
  withAfter: boolean;
  duringCallSeq?: CallInfo['call_seq'];
  afterCallSeq?: CallInfo['call_seq'];
  resultCallSeq?: CallInfo['call_seq'];
}

const CallSeq = ({
  withAfter,
  duringCallSeq,
  afterCallSeq,
  resultCallSeq,
}: CallSeqProps) => (
  <Row>
    <Col span={5}><Typography.Text strong>Call sequence</Typography.Text></Col>
    <Col span={5}>
      <Typography.Text>{duringCallSeq}</Typography.Text>
    </Col>
    <Col span={5}>
      {withAfter && (
        <Typography.Text>{afterCallSeq}</Typography.Text>
      )}
    </Col>
    <Col span={4} />
    <Col span={5}>{resultCallSeq}</Col>
  </Row>
);

export default CallSeq;
