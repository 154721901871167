import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';
import { GetPresignedUrlRequest } from '../../types';

interface UsePreSignedAudioUrlArgs {
  audioUrl?: string | null;
  request: GetPresignedUrlRequest;
}

const usePreSignedAudioUrl = ({ audioUrl, request }: UsePreSignedAudioUrlArgs) => {
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (!audioUrl) {
      return null;
    }

    setLoading(true);
    try {
      const response = await request(audioUrl);

      setLoading(false);

      return response.data.presignedUrl;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }

      setLoading(false);

      return null;
    }
  }, [audioUrl, request]);

  return {
    loading,
    fetchData,
  };
};

export default usePreSignedAudioUrl;
