import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import PageLayoutBase from '@marrlab-app-shared/components/pageLayout';

import useIsAuthorized from '../../hooks/auth/useIsAuthorized';
import useGlobalErrorHandling from '../../hooks/axious/useGlobalErrorHandling';
import config from '../../utils/config';

const PageLayout = ({ children }: PropsWithChildren) => {
  useGlobalErrorHandling();

  const { isAuthorized } = useIsAuthorized();

  if (!isAuthorized) {
    return null;
  }

  return (
    <PageLayoutBase
      mode={config.MODE}
      title="Calls review queue"
    >
      {children}
      <Outlet />
    </PageLayoutBase>
  );
};

export default PageLayout;
