import {
  Modal,
  Select,
  Input,
  Rate,
  Form,
} from 'antd';
import { AxiosResponse } from 'axios';

import CallAnnotationModalFooter from './components/callAnnotationModalFooter';
import useCallAnnotationModalState from './hooks/useCallAnnotationModalState';
import useCallAnnotationModalLogic from './hooks/useCallAnnotationModalLogic';
import useCallAnnotationModalValidation from './hooks/useCallAnnotationModalValidation';
import { CallAnnotation } from '../../../../types';

interface CallAnnotationModalProps {
  isOpen: boolean;
  callId: number;
  onConfirm: () => void;
  onCancel: () => void;
  initialAnnotation: CallAnnotation | null;
  createCallAnnotation: (annotation: CallAnnotation) => Promise<AxiosResponse<CallAnnotation>>;
  editCallAnnotation: (annotation: CallAnnotation) => Promise<AxiosResponse<CallAnnotation>>;
}

const CallAnnotationModal = ({
  isOpen,
  callId,
  initialAnnotation,
  onConfirm: onConfirmBase,
  onCancel,
  createCallAnnotation,
  editCallAnnotation,
}: CallAnnotationModalProps) => {
  const { annotationErrors, isValid, clearErrorForField } = useCallAnnotationModalValidation();

  const {
    editMode,
    annotation,
    onAnnotationFieldUpdate,
  } = useCallAnnotationModalState({
    isOpen,
    callId,
    initialAnnotation,
    clearErrorForField,
  });

  const { saving, onConfirm } = useCallAnnotationModalLogic({
    annotation,
    isValid,
    onConfirm: onConfirmBase,
    createCallAnnotation,
    editCallAnnotation,
  });

  return (
    <Modal
      title={editMode ? 'Edit annotation' : 'Add new annotation'}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="800px"
      footer={(
        <CallAnnotationModalFooter
          disabled={saving}
          saveButtonLabel={editMode ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form layout="vertical" className="py-4">
        <Form.Item label="Task completed" required>
          <Select
            value={annotation.taskCompleted}
            onChange={(value) => onAnnotationFieldUpdate('taskCompleted', value)}
            options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
            disabled={saving}
          />
        </Form.Item>
        <Form.Item label="Call outcome">
          <Input.TextArea
            showCount
            value={annotation.callOutcome}
            onChange={(event) => onAnnotationFieldUpdate('callOutcome', event.target.value)}
            maxLength={1000}
            disabled={saving}
          />
        </Form.Item>
        <Form.Item
          label="Human like rating"
          required
          validateStatus={annotationErrors.humanLikeRating ? 'error' : undefined}
          help={annotationErrors.humanLikeRating}
        >
          <Rate
            key={annotation.callAnnotationId}
            value={annotation.humanLikeRating}
            onChange={(value) => onAnnotationFieldUpdate('humanLikeRating', value)}
            disabled={saving}
          />
        </Form.Item>
        <Form.Item label="Call quality problems to address">
          <Input.TextArea
            showCount
            value={annotation.callQualityProblems}
            onChange={(event) => onAnnotationFieldUpdate('callQualityProblems', event.target.value)}
            maxLength={1000}
            disabled={saving}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CallAnnotationModal;
