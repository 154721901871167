import type { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { TableColumnsType, TablePaginationConfig, TableProps } from 'antd';

import { CallHistory } from '../../../api/callsHistory/types';
import { idColumnKey } from '../constants/columnsInitialState';
import { SortOrder } from '../../../constants';
import { getDefaultSorting } from '../utils/searchParams';

const defaultSortBy: keyof CallHistory = idColumnKey;

interface UseSortingArgs {
  setColumns: Dispatch<SetStateAction<TableColumnsType<CallHistory>>>;
}

const useSorting = ({ setColumns }: UseSortingArgs) => {
  const defaultSorting = getDefaultSorting();

  const [sortBy, setSortBy] = useState<keyof CallHistory>(defaultSorting.sortBy);
  const [sortDirection, setSortDirection] = useState<SortOrder>(defaultSorting.order);

  const onSortChange: TableProps<CallHistory>['onChange'] = useCallback((
    _: TablePaginationConfig,
    _1: Record<string, FilterValue | null>,
    sorter: SorterResult<CallHistory> | SorterResult<CallHistory>[],
    extra: TableCurrentDataSource<CallHistory>,
  ) => {
    if (extra.action !== 'sort' || !sorter || Array.isArray(sorter)) {
      return;
    }

    const columnKey = sorter.columnKey as keyof CallHistory ?? defaultSortBy;
    const sortOrder = sorter.order ?? 'ascend';

    setSortBy(columnKey);
    setSortDirection(SortOrder[sortOrder]);

    setColumns((columns) => (
      columns.map((column) => ({
        ...column,
        sortOrder: column.key === columnKey ? sortOrder : undefined,
      }))
    ));
  }, [setColumns]);

  return {
    sortBy,
    sortDirection,
    onSortChange,
  };
};

export default useSorting;
