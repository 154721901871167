import { PropsWithChildren } from 'react';
import {
  Flex,
  Layout,
  Spin,
  Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  ClerkLoading,
  OrganizationSwitcher,
  SignedIn,
  SignedOut,
  UserButton,
} from '@clerk/clerk-react';

import { useOrganizationMemberships } from './hooks/useOrganizationMemberships';

import Logo from '../../assets/logo.png';

import './index.scss';

const {
  Header,
  Content,
  Footer,
} = Layout;

interface PageLayoutProps extends PropsWithChildren {
  mode: string;
  title: string;
}

const PageLayout = ({
  children,
  mode,
  title,
}: PageLayoutProps) => {
  const { showOrganizationSwitcher } = useOrganizationMemberships();

  return (
    <Layout className="min-h-screen">
      <Header className="header-wrapper">
        <div className="header">
          <Link to="/" className="logo-wrapper">
            <img src={Logo} alt="logo" className="header-logo" />
            <Flex className="env-description">
              <Typography.Text>
                Env:
                {' '}
                {mode}
              </Typography.Text>
            </Flex>
          </Link>
          <Typography.Title level={2} className="page-title">
            {title}
          </Typography.Title>
          <Flex align="center" className="user-info-wrapper">
            <SignedIn>
              {showOrganizationSwitcher && (
                <OrganizationSwitcher
                  createOrganizationUrl=""
                  organizationProfileUrl=""
                  hidePersonal
                  hideSlug
                />
              )}
              <UserButton
                showName
              />
            </SignedIn>
            <SignedOut>
              <div />
            </SignedOut>
            <ClerkLoading>
              <Spin />
            </ClerkLoading>
          </Flex>
        </div>
      </Header>
      <Content className="background overflow-y-scroll p-6 z-2 container mx-auto pb-12">
        {children}
      </Content>
      <Footer className="mt-auto text-center">©2024 MarrLabs</Footer>
    </Layout>
  );
};

export default PageLayout;
