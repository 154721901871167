import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';
import { useUser } from '@clerk/clerk-react';

import { CallInfo } from '../../../api/callsHistory/types';
import { submitCallUserInfo } from '../../../api/callsHistory';
import CustomersQueueSizeProvider from '../../../components/customersQueueSizeProvider';

interface UseCallInfoLogicArgs {
  callId: string;
  result: Partial<CallInfo> | null;
  refetch: () => Promise<void>;
  isValid: (state: Partial<CallInfo> | null) => boolean;
  openNextCallNotification: () => void;
}

const useCallInfoLogic = ({
  result,
  callId,
  refetch,
  isValid,
  openNextCallNotification,
}: UseCallInfoLogicArgs) => {
  const {
    refetch: refetchCustomerQueueSize,
  } = CustomersQueueSizeProvider.useCustomersQueueSizeActions();

  const [saving, setSaving] = useState(false);

  const { user } = useUser();

  const onSubmit = useCallback(async () => {
    if (!result) {
      return;
    }

    if (!isValid(result)) {
      return;
    }

    setSaving(true);

    try {
      await submitCallUserInfo(
        callId,
        {
          call_log_uuid: result.call_log_uuid,
          source: 'editor',
          call_params: result.call_params,
          has_outstanding_questions: result.has_outstanding_questions,
          answered: result.answered,
          call_dropped: result.call_dropped,
          spoke: result.spoke,
          customer_status: result.customer_status,
          notes: result.notes?.trim(),
          source_description: user?.primaryEmailAddress?.emailAddress ?? '',
        },
      );

      openNextCallNotification();

      await refetch();
      refetchCustomerQueueSize();
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({
          content: error.response?.data?.message ?? error.response?.data?.detail ?? error.message,
          type: 'error',
          duration: 10,
        });
      }
    }

    setSaving(false);
  }, [
    result,
    isValid,
    callId,
    user?.primaryEmailAddress,
    openNextCallNotification,
    refetch,
    refetchCustomerQueueSize,
  ]);

  return {
    saving,
    onSubmit,
  };
};

export default useCallInfoLogic;
