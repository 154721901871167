import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { Collapse, Typography } from 'antd';

import './index.scss';

interface FiltersPanelProps extends PropsWithChildren {
  search: ReactNode;
  defaultOpen?: boolean;
}

const FiltersPanel = ({
  search,
  children,
  defaultOpen = false,
}: FiltersPanelProps) => {
  const [expanded, setExpanded] = useState(defaultOpen);

  const toggle = useCallback(() => {
    setExpanded((state) => !state);
  }, []);

  return (
    <Collapse
      activeKey={expanded ? '1' : undefined}
      className="filters-panel mb-4"
      ghost
      collapsible="disabled"
      items={[{
        key: '1',
        label: <Typography.Link onClick={toggle}>Show filters</Typography.Link>,
        extra: search,
        children,
      }]}
    />
  );
};

export default FiltersPanel;
