import { CallsHistoryFilters } from '../types';
import { CallHistory, GetCallsHistoryParams } from '../../../api/callsHistory/types';
import { SortOrder } from '../../../constants';
import { idColumnKey } from '../constants/columnsInitialState';

let LAST_FILTERS_AND_SEARCH: URLSearchParams | null = null;

export const saveLastFiltersAndSearch = () => {
  const params = new URLSearchParams(window.location.search);

  // stored in provider and might be changed from details page as well
  params.delete('customer');

  LAST_FILTERS_AND_SEARCH = params;
};

export const getLastFiltersAndSearch = (): URLSearchParams | null => {
  if (!LAST_FILTERS_AND_SEARCH) {
    return null;
  }

  const params = new URLSearchParams(LAST_FILTERS_AND_SEARCH);

  LAST_FILTERS_AND_SEARCH = null;

  return params;
};

export const getDefaultFilters = (): CallsHistoryFilters => {
  const params = new URLSearchParams(window.location.search);

  const rawReviewStatus = params.get('reviewStatus');

  const reviewStatus = rawReviewStatus ? parseInt(rawReviewStatus, 10) as 1 | 0 | -1 : 0;

  return {
    reviewStatus,
    campaign: params.get('campaign') ?? undefined,
    searchBy: params.get('user') ?? 'callLogUuid',
  };
};

export const getDefaultCustomer = (): CallsHistoryFilters => {
  const params = new URLSearchParams(window.location.search);

  return {
    customer: params.get('customer') ?? undefined,
  };
};

export const getDefaultPagination = (): { pageSize: number, page: number } => {
  const params = new URLSearchParams(window.location.search);

  const rawTake = params.get('take');
  const rawSkip = params.get('skip');

  const take = rawTake ? parseInt(rawTake, 10) : 20;
  const skip = rawSkip ? parseInt(rawSkip, 10) : 0;

  return {
    pageSize: take,
    page: (skip / take) + 1,
  };
};

export const getDefaultSearch = (): string => {
  const params = new URLSearchParams(window.location.search);

  return params.get('search') ?? '';
};

export const getDefaultSorting = (): Pick<GetCallsHistoryParams, 'sortBy' | 'order'> => {
  const params = new URLSearchParams(window.location.search);

  const rawOrder = params.get('order') as SortOrder | null;
  const rawSortBy = params.get('sortBy') as keyof CallHistory | null;

  return {
    order: rawOrder ?? SortOrder.ascend,
    sortBy: rawSortBy ?? idColumnKey,
  };
};
