import { useParams } from 'react-router-dom';
import CallTranscript from '@marrlab-app-shared/components/callTranscript';
import CallAnnotations from '@marrlab-app-shared/components/callAnnotations';

import CallParams from '../../features/callParams';
import CallReview from '../../features/callReview';
import useCallData from './hooks/useCallData';
import { createCallAnnotation, editCallAnnotation, getPreSignedAudioUrl } from '../../api/callsHistory';

const CallDetails = () => {
  const { callId } = useParams();

  const { data, loading, refetch: refetchCallData } = useCallData({ callId });

  return (
    <>
      <CallParams loading={loading} data={data} />
      <CallTranscript
        loading={loading}
        data={data?.callTranscriptJson}
        audioUrl={data?.audioUrl ?? null}
        getPreSignedUrlRequest={getPreSignedAudioUrl}
      />
      {data && (
        <CallReview callId={data?.callLogUuid} />
      )}
      {callId && (
        <CallAnnotations
          loading={loading}
          annotations={data?.annotations ?? []}
          callId={parseInt(callId, 10)}
          refetchCallData={refetchCallData}
          createCallAnnotation={createCallAnnotation}
          editCallAnnotation={editCallAnnotation}
        />
      )}
    </>
  );
};

export default CallDetails;
