import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { Page } from '@marrlab-app-shared/types';

import { getCallsHistory } from '../../../api/callsHistory';
import { CallHistory, GetCallsHistoryParams } from '../../../api/callsHistory/types';
import { useFiltersSearchParams } from './useFiltersSearchParams';
import { customerAllOption } from '../../../constants';

const useCallsHistoryData = ({
  skip,
  take,
  sortBy,
  order,
  search,
  campaign,
  customer,
  reviewStatus,
  searchBy,
}: GetCallsHistoryParams) => {
  const { updateSearchParams } = useFiltersSearchParams();
  const [data, setData] = useState<Page<CallHistory> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchCallsHistory = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        skip,
        take,
        sortBy,
        order,
        search,
        campaign,
        customer: customer === customerAllOption ? undefined : customer,
        reviewStatus,
        searchBy,
      };

      updateSearchParams(params);
      const response = await getCallsHistory(params);

      setData(response.data);
    } catch (error) {
      console.error(error);
      message.open({ content: (error as { message: string }).message, type: 'error', duration: 10 });
    }

    setLoading(false);
  }, [
    reviewStatus,
    customer,
    campaign,
    order,
    search,
    skip,
    sortBy,
    take,
    searchBy,
    updateSearchParams,
  ]);

  useEffect(() => {
    fetchCallsHistory();
  }, [fetchCallsHistory]);

  return {
    data,
    loading,
    fetchCallsHistory,
  };
};

export default useCallsHistoryData;
