import {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
} from 'react';

import Player from '../components/player';
import useAudioPlayerState from '../hooks/useAudioPlayerState';
import { CurrentlyPlaying, TimeRange } from '../types';

interface AudioPlayerContextArgs {
  currentlyPlayingId?: string;
  open: (data: CurrentlyPlaying & TimeRange) => void;
}

const AudioPlayerContext = createContext<AudioPlayerContextArgs>({
  open: () => {
  },
});

const AudioPlayerProvider = ({ children }: PropsWithChildren) => {
  const {
    playing,
    duration,
    currentTime,
    audioRef,
    progressBarRef,
    currentlyPlaying,
    open,
    play,
    pause,
    stop,
    onEnded,
    onLoadedMetadata,
    onProgressChange,
  } = useAudioPlayerState();

  const value = useMemo(() => ({
    currentlyPlayingId: currentlyPlaying?.id,
    open,
  }), [currentlyPlaying?.id, open]);

  return (
    <AudioPlayerContext.Provider value={value}>
      {children}
      <Player
        progressBarRef={progressBarRef}
        ref={audioRef}
        playing={playing}
        duration={duration}
        currentTime={currentTime}
        currentlyPlaying={currentlyPlaying}
        play={play}
        pause={pause}
        stop={stop}
        onEnded={onEnded}
        onLoadedMetadata={onLoadedMetadata}
        onProgressChange={onProgressChange}
      />
    </AudioPlayerContext.Provider>
  );
};

AudioPlayerProvider.useAudioPlayer = () => useContext(AudioPlayerContext);

export default AudioPlayerProvider;
