import {
  Button,
  Card,
  Flex,
  Spin,
  Typography,
} from 'antd';

import useCallInfoData from './hooks/useCallInfoData';

import Header from './components/header';
import CheckboxRow from './components/checkboxRow';
import SourceDescription from './components/sourceDescription';
import Created from './components/created';
import SelectRow from './components/selectRow';
import CallParamRow from './components/callParamRow';
import TextCallParamRow from './components/textCallParamRow';
import NotesRow from './components/notesRow';
import CallSeq from './components/callSeq';
import useCallInfoState from './hooks/useCallInfoState';
import useCallInfoLogic from './hooks/useCallInfoLogic';
import useNextCallToReview from './hooks/useNextCallToReview';
import useCallInfoValidation from './hooks/useCallInfoValidation';
import { CallInfo } from '../../api/callsHistory/types';
import { numberCallParamsKeys } from './constants';

import './index.scss';

interface CallReviewProps {
  callId: string;
}

const emptyData: CallInfo[] = [];

const CallReview = ({ callId }: CallReviewProps) => {
  const { data, loading, refetch } = useCallInfoData({ callId });

  const {
    isValid,
    errors,
    clearErrors,
  } = useCallInfoValidation();

  const {
    disabled,
    submitted,
    during,
    after,
    result,
    preferMap,
    onUpdate,
    onPreferUpdate,
    onCallParamUpdate,
    onPreferUpdateForCallParam,
  } = useCallInfoState({ data: data ?? emptyData, clearErrors });

  const {
    nextCallLoading,
    openNextCall,
    openNextCallNotification,
  } = useNextCallToReview();

  const { saving, onSubmit } = useCallInfoLogic({
    isValid,
    callId,
    result,
    refetch,
    openNextCallNotification,
  });

  const callParams = after?.call_params
    ?? during?.call_params;

  const withAfter = !!after;

  return (
    <Card
      title="Call summary review"
      className="bg-white rounded shadow-lg mb-6"
      extra={submitted ? (
        <Flex align="center" gap={12}>
          <Button
            type="primary"
            onClick={openNextCall}
            loading={nextCallLoading}
          >
            Open next call
          </Button>
          <Typography.Title
            className="call-review-submitted-title"
            level={5}
          >
            Submitted
          </Typography.Title>
        </Flex>
      ) : (
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={disabled || saving}
        >
          Submit review
        </Button>
      )}
    >
      <Flex gap={12} vertical className="call-review-wrapper">
        {loading ? (
          <Spin className="m-auto" size="large" />
        ) : (
          <>
            <Header withAfter={withAfter} />
            <Created
              withAfter={withAfter}
              duringCreated={during?.created}
              afterCreated={after?.created}
              resultCreated={result?.created}
            />
            <CallSeq
              withAfter={withAfter}
              duringCallSeq={during?.call_seq}
              afterCallSeq={after?.call_seq}
              resultCallSeq={result?.call_seq}
            />
            <SourceDescription
              sourceDescription={result?.source_description}
            />
            {callParams && Object.keys(callParams).map((key) => (
              numberCallParamsKeys.includes(key) ? (
                <CallParamRow
                  key={key}
                  withAfter={withAfter}
                  title={key}
                  propName={key}
                  afterValue={after?.call_params?.[key]}
                  duringValue={during?.call_params?.[key]}
                  resultValue={result?.call_params?.[key]}
                  prefer={preferMap?.call_params[key]}
                  disabled={disabled || saving}
                  onUpdate={onCallParamUpdate}
                  onPreferUpdate={onPreferUpdateForCallParam}
                />
              ) : (
                <TextCallParamRow
                  key={key}
                  withAfter={withAfter}
                  title={key}
                  propName={key}
                  afterValue={after?.call_params?.[key]}
                  duringValue={during?.call_params?.[key]}
                  resultValue={result?.call_params?.[key]}
                  prefer={preferMap?.call_params[key]}
                  disabled={disabled || saving}
                  onUpdate={onCallParamUpdate}
                  onPreferUpdate={onPreferUpdateForCallParam}
                />
              )
            ))}
            <CheckboxRow
              withAfter={withAfter}
              title="Has outstanding questions"
              propName="has_outstanding_questions"
              afterValue={after?.has_outstanding_questions}
              duringValue={during?.has_outstanding_questions}
              resultValue={result?.has_outstanding_questions}
              prefer={preferMap?.has_outstanding_questions}
              disabled={disabled || saving}
              onUpdate={onUpdate}
              onPreferUpdate={onPreferUpdate}
            />
            <SelectRow
              withAfter={withAfter}
              title="Answered"
              propName="answered"
              afterValue={after?.answered}
              duringValue={during?.answered}
              resultValue={result?.answered}
              prefer={preferMap?.answered}
              disabled={disabled || saving}
              options={[
                {
                  value: 'answering_machine',
                  label: 'Answering machine',
                },
                {
                  value: 'answered',
                  label: 'Human answered',
                },
                {
                  value: 'unanswered',
                  label: 'No answer',
                },
              ]}
              onUpdate={onUpdate}
              onPreferUpdate={onPreferUpdate}
              resultError={errors.answered}
            />
            <CheckboxRow
              withAfter={withAfter}
              title="Spoke"
              propName="spoke"
              afterValue={after?.spoke}
              duringValue={during?.spoke}
              resultValue={result?.spoke}
              prefer={preferMap?.spoke}
              disabled={disabled || saving}
              onUpdate={onUpdate}
              onPreferUpdate={onPreferUpdate}
              resultError={errors.spoke}
            />
            <CheckboxRow
              withAfter={withAfter}
              title="Call dropped"
              propName="call_dropped"
              afterValue={after?.call_dropped}
              duringValue={during?.call_dropped}
              resultValue={result?.call_dropped}
              prefer={preferMap?.call_dropped}
              disabled={disabled || saving}
              onUpdate={onUpdate}
              onPreferUpdate={onPreferUpdate}
            />
            <SelectRow
              withAfter={withAfter}
              title="Customer status"
              propName="customer_status"
              afterValue={after?.customer_status}
              duringValue={during?.customer_status}
              resultValue={result?.customer_status}
              prefer={preferMap?.customer_status}
              disabled={disabled || saving}
              options={[
                {
                  value: 'not_applicable',
                  label: 'Not applicable',
                },
                {
                  value: 'no_bots',
                  label: 'Unwilling to engage AI Agent',
                },
                {
                  value: 'no_call',
                  label: 'Do not want to call back',
                },
                {
                  value: 'not_available',
                  label: 'Not Available',
                },
                {
                  value: 'wrong_customer',
                  label: 'Wrong customer',
                },
              ]}
              onUpdate={onUpdate}
              onPreferUpdate={onPreferUpdate}
              resultError={errors.customer_status}
            />
            <NotesRow
              notes={result?.notes}
              onUpdate={onUpdate}
              disabled={disabled || saving}
            />
          </>
        )}
      </Flex>
    </Card>
  );
};

export default CallReview;
