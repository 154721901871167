export const debounce = <F extends (...args: never[]) => void>(func: F, waitFor: number) => {
  let timeout: number | null = null;

  return (...args: Parameters<F>): Promise<void> => new Promise((resolve) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(() => resolve(func(...args)), waitFor);
  });
};

export default debounce;
