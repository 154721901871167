import { Button, Flex } from 'antd';

interface CallAnnotationModalFooterProps {
  disabled: boolean;
  saveButtonLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const CallAnnotationModalFooter = ({
  disabled,
  saveButtonLabel,
  onCancel,
  onConfirm,
}: CallAnnotationModalFooterProps) => (
  <Flex gap={8} justify="flex-end">
    <Button onClick={onCancel} disabled={disabled}>
      Cancel
    </Button>
    <Button type="primary" onClick={onConfirm} disabled={disabled}>
      {saveButtonLabel}
    </Button>
  </Flex>
);

export default CallAnnotationModalFooter;
