import { useCallback, useEffect, useState } from 'react';

import { CallInfo } from '../../../api/callsHistory/types';
import {
  OnCallParamUpdateArgs,
  OnPreferForCallParamUpdateArgs,
  OnPreferUpdateArgs,
  OnReviewUpdateArgs,
  PreferState,
} from '../types';
import { getInitialState } from '../mappers';

interface UseCallInfoStateArgs {
  data: CallInfo[];
  clearErrors: () => void;
}

const useCallInfoState = ({ data, clearErrors }: UseCallInfoStateArgs) => {
  const [during, setDuring] = useState<CallInfo | null>(null);
  const [after, setAfter] = useState<CallInfo | null>(null);
  const [result, setResult] = useState<Partial<CallInfo> | null>(null);
  const [preferMap, setPreferMap] = useState<PreferState>(null);

  useEffect(() => {
    const initialState = getInitialState(data);

    setDuring(initialState.during);
    setAfter(initialState.after);
    setResult(initialState.result);
    setPreferMap(initialState.preferMap);
  }, [data]);

  const onUpdate = useCallback(({ value, propName }: OnReviewUpdateArgs) => {
    setResult((oldState) => {
      if (oldState === null) {
        return null;
      }

      return { ...oldState, [propName]: value };
    });

    if (propName === 'answered' || propName === 'spoke') {
      clearErrors();
    }
  }, [clearErrors]);

  const onCallParamUpdate = useCallback(({ value, propName }: OnCallParamUpdateArgs) => {
    setResult((oldState) => {
      if (oldState === null) {
        return null;
      }

      return {
        ...oldState,
        call_params: {
          ...oldState.call_params,
          [propName]: value,
        },
      };
    });
  }, []);

  const onPreferUpdate = useCallback(({ value, propName }: OnPreferUpdateArgs) => {
    setPreferMap((state) => {
      if (state === null) {
        return null;
      }

      return {
        ...state,
        [propName]: value,
      };
    });

    onUpdate({
      propName,
      value: value === 'after' ? after?.[propName] : during?.[propName],
    });

    if (propName === 'answered' || propName === 'spoke') {
      clearErrors();
    }
  }, [after, clearErrors, during, onUpdate]);

  const onPreferUpdateForCallParam = useCallback(({
    value,
    propName,
  }: OnPreferForCallParamUpdateArgs) => {
    setPreferMap((state) => {
      if (state === null) {
        return null;
      }

      return {
        ...state,
        call_params: {
          ...state.call_params,
          [propName]: value,
        },
      };
    });

    onCallParamUpdate({
      propName,
      value: value === 'after' ? after?.call_params?.[propName] : during?.call_params?.[propName],
    });
  }, [after?.call_params, during?.call_params, onCallParamUpdate]);

  const submitted = !!data.find((item) => item.source === 'editor');
  const disabled = !data.length || submitted;

  return {
    disabled,
    submitted,
    after,
    during,
    result,
    preferMap,
    onUpdate,
    onPreferUpdate,
    onCallParamUpdate,
    onPreferUpdateForCallParam,
  };
};

export default useCallInfoState;
