import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';

import { CustomerQueueSize } from '../../../api/common/types';
import { getCustomersQueueSize } from '../../../api/callsHistory';

const useCustomersQueueSize = () => {
  const [data, setData] = useState<CustomerQueueSize[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getCustomersQueueSize();

      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    refetch: fetchData,
  };
};

export default useCustomersQueueSize;
