import { message } from 'antd';

import { CallTranscript } from '../../../types';
import { formatMinutesDuration } from '../../../utils/date/time';
import { getUserToAgentDelay } from './delay';

interface FormatTranscriptForCopyOptions {
  markdown: boolean;
}

export const formatTranscriptForCopy = (
  transcript: CallTranscript[],
  options?: FormatTranscriptForCopyOptions,
) => {
  if (!transcript?.length) {
    return '';
  }

  return transcript.map(({
    role: rawRole,
    words,
    content,
  }, index) => {
    let role = `${rawRole[0].toUpperCase()}${rawRole.slice(1)}`;

    role = options?.markdown ? `**${role}**` : role;

    const prevMessage = transcript[index - 1];

    const timing = words.length
      ? `${formatMinutesDuration(words[0].start)} - ${formatMinutesDuration(words[words.length - 1].end)}`
      : '';

    const rawDelay = getUserToAgentDelay({ role, prevMessage, words });
    const delay = rawDelay ? ` ${rawDelay}` : '';

    return `${role} ${timing}${delay}: ${content}`;
  }).join('\n');
};

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error(error);
    message.open({ content: 'Failed to copy transcript into clipboard', type: 'error', duration: 10 });
  }
};
