import { useCallback, useState } from 'react';

import { CallAnnotation } from '../../../../../types';

interface AnnotationErrors {
  humanLikeRating: string;
}

const useCallAnnotationModalValidation = () => {
  const [annotationErrors, setAnnotationErrors] = useState<AnnotationErrors>({
    humanLikeRating: '',
  });

  const isValid = useCallback((annotation: CallAnnotation) => {
    const newState = {
      humanLikeRating: annotation.humanLikeRating !== 0 ? '' : 'This field is mandatory.',
    };

    setAnnotationErrors({
      humanLikeRating: annotation.humanLikeRating !== 0 ? '' : 'This field is mandatory.',
    });

    return Object.values(newState).every((field) => !field);
  }, []);

  const clearErrorForField = useCallback((propName: string) => {
    setAnnotationErrors((state) => ({
      ...state,
      [propName]: '',
    }));
  }, []);

  return {
    annotationErrors,
    isValid,
    clearErrorForField,
  };
};

export default useCallAnnotationModalValidation;
