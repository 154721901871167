import { Col, Row, Typography } from 'antd';
import { CallInfo } from '../../../../api/callsHistory/types';
import { formatDate } from '../../../../utils/date/date';

interface CreatedProps {
  withAfter: boolean;
  duringCreated?: CallInfo['created'];
  afterCreated?: CallInfo['created'];
  resultCreated?: CallInfo['created'];
}

const Created = ({
  withAfter,
  duringCreated,
  afterCreated,
  resultCreated,
}: CreatedProps) => (
  <Row>
    <Col span={5}><Typography.Text strong>Created</Typography.Text></Col>
    <Col span={5}>
      <Typography.Text>{duringCreated ? formatDate(duringCreated) : ''}</Typography.Text>
    </Col>
    <Col span={5}>
      {withAfter && (
        <Typography.Text>{afterCreated ? formatDate(afterCreated) : ''}</Typography.Text>
      )}
    </Col>
    <Col span={4} />
    <Col span={5}>{resultCreated ? formatDate(resultCreated) : ''}</Col>
  </Row>
);

export default Created;
