import { Flex, Typography } from 'antd';

const NotFound = () => (
  <Flex vertical align="center" className="mx-auto">
    <Typography.Title level={2}>404 Page Not Found</Typography.Title>
    <Typography.Title level={5}>This page could not be found.</Typography.Title>
  </Flex>
);

export default NotFound;
