import {
  Button,
  Card,
  Descriptions,
  Flex,
  Skeleton,
  Typography,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { CallHistory } from '../../api/callsHistory/types';
import useColumns from './hooks/useColumns';
import useNavigate from '../../hooks/navigation/useNavigate';

import './index.scss';

interface CallParamsProps {
  data: CallHistory | null;
  loading?: boolean;
}

const CallParams = ({ data, loading }: CallParamsProps) => {
  const {
    retellColumns,
    commonColumns,
  } = useColumns({ data });

  const { navigateToMain } = useNavigate();

  return (
    <Card
      title={(
        <Flex align="center" gap={16}>
          <Button icon={<ArrowLeftOutlined />} onClick={() => navigateToMain(true)} />
          <Typography.Title level={4} className="call-params-title">Call parameters</Typography.Title>
        </Flex>
      )}
      className="bg-white rounded shadow-lg mb-6"
    >
      <Skeleton
        active
        title
        paragraph={{ rows: 5 }}
        className="mb-4"
        loading={loading}
      >
        <Typography.Title level={4} className="call-params-subtitle">Common</Typography.Title>
        <Descriptions
          items={commonColumns}
          column={2}
        />
      </Skeleton>
      <Skeleton
        active
        title
        paragraph={{ rows: 5 }}
        loading={loading}
      >
        <Typography.Title level={4} className="call-params-subtitle">Retell</Typography.Title>
        <Descriptions
          items={retellColumns}
          column={2}
        />
      </Skeleton>
    </Card>
  );
};

export default CallParams;
