import axios from 'axios';
import { CallAnnotation, Page } from '@marrlab-app-shared/types';

import {
  CallHistory,
  GetCallsHistoryParams,
  GetSearchableDataParams,
  GetPreSignedUrlResponse,
  CallInfo,
  SubmitCallInfoParams,
} from './types';
import config from '../../utils/config';
import { Campaign, Customer, CustomerQueueSize } from '../common/types';

export const getCallsHistory = async (params: GetCallsHistoryParams) => (
  axios.get<Page<CallHistory>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/review-queue/`, { params })
);

export const getCallDetails = async (callId: string) => (
  axios.get<CallHistory>(`${config.CALLS_HISTORY_API_BASE_URL}/api/review-queue/${callId}`)
);

export const getCampaignsRequest = async (params: GetSearchableDataParams) => (
  axios.get<Page<Campaign>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/campaigns/`, { params })
);

export const getCustomersRequest = async (params: GetSearchableDataParams) => (
  axios.get<Page<Customer>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/customers/`, { params })
);

export const getPreSignedAudioUrl = async (audioUrl: string) => (
  axios.post<GetPreSignedUrlResponse>(`${config.API_BASE_URL}/api/audio-url/`, {
    s3Url: audioUrl,
    download: true,
  })
);

export const getCallUserInfo = async (callId: string) => (
  axios.get<CallInfo[]>(`${config.USER_INFO_API_BASE_URL}/user-info/v1/get-sources/${callId}/`)
);

export const submitCallUserInfo = async (callId: string, params: SubmitCallInfoParams) => (
  axios.post(`${config.USER_INFO_API_BASE_URL}/user-info/v1/put-source/${callId}/`, params)
);

export const getCustomersQueueSize = async () => (
  axios.get<CustomerQueueSize[]>(`${config.CALLS_HISTORY_API_BASE_URL}/api/review-queue/customers-queue-size`)
);

export const createCallAnnotation = async (annotation: CallAnnotation) => (
  axios.post<CallAnnotation>(
    `${config.CALLS_HISTORY_API_BASE_URL}/api/calls-history/${annotation.callLogId}/annotation`,
    annotation,
  )
);

export const editCallAnnotation = async (annotation: CallAnnotation) => (
  axios.put<CallAnnotation>(
    `${config.CALLS_HISTORY_API_BASE_URL}/api/calls-history/${annotation.callLogId}/annotation/${annotation.callAnnotationId}`,
    annotation,
  )
);
