import { DescriptionsItemType } from 'antd/es/descriptions';

import { idColumnKey } from '../../callsHistory/constants/columnsInitialState';

export const initialCommonColumns: DescriptionsItemType[] = [
  {
    key: idColumnKey,
    label: 'ID',
    children: '',
  },
  {
    key: 'callLogUuid',
    label: 'Call uuid',
    children: '',
  },
  {
    key: 'startDate',
    label: 'Started',
    children: '',
  },
  {
    key: 'callDurationSec',
    label: 'Duration',
    children: '',
  },
  {
    key: 'agentNumber',
    label: 'Agent number',
    children: '',
  },
  {
    key: 'userNumber',
    label: 'User number',
    children: '',
  },
  {
    key: 'operatorNumber',
    label: 'Origination number',
    children: '',
  },
  {
    key: 'customer',
    label: 'Customer',
    children: '',
  },
  {
    key: 'campaign',
    label: 'Campaign',
    children: '',
  },
  {
    key: 'audioUrl',
    label: 'Audio URL',
    children: '',
  },
];

export const initialRetellColumns: DescriptionsItemType[] = [
  {
    key: 'retellCallId',
    label: 'Retell call ID',
    children: '',
  },
  {
    key: 'retellAgentId',
    label: 'Retell agent ID',
    children: '',
  },
  {
    key: 'audioProtocol',
    label: 'Audio protocol',
    children: '',
  },
  {
    key: 'callCompletion',
    label: 'Call completion',
    children: '',
  },
  {
    key: 'taskCompletion',
    label: 'Task completion',
    children: '',
  },
  {
    key: 'userSentiment',
    label: 'User sentiment',
    children: '',
  },
  {
    key: 'disconnectionReason',
    label: 'Disconnection reason',
    children: '',
  },
  {
    key: 'averageEndToEndLatency',
    label: 'Average end to end latency',
    children: '',
  },
  {
    key: 'summary',
    label: 'Summary',
    children: '',
    span: 2,
  },
];
