import { CallInfo } from '../../../api/callsHistory/types';
import { PreferState } from '../types';

export const getInitialState = (
  data: CallInfo[],
): {
  during: CallInfo | null;
  after: CallInfo | null;
  result: Partial<CallInfo> | null;
  preferMap: PreferState;
} => {
  if (!data.length) {
    return {
      during: null,
      after: null,
      result: null,
      preferMap: null,
    };
  }

  const after = data.find((item) => item.source === 'after') ?? null;
  const during = data.find((item) => item.source === 'during') ?? null;
  const editor = data.find((item) => item.source === 'editor') ?? null;

  const result: Partial<CallInfo> = {
    call_log_uuid: after?.call_log_uuid ?? during?.call_log_uuid,
    source: 'editor',
    call_params: after?.call_params ?? during?.call_params,
    has_outstanding_questions: after?.has_outstanding_questions
      ?? during?.has_outstanding_questions,
    call_dropped: after?.call_dropped ?? during?.call_dropped,
    spoke: after?.spoke ?? during?.spoke,
    answered: after?.answered ?? during?.answered,
    customer_status: after?.customer_status ?? during?.customer_status,
    source_description: after?.source_description ?? during?.source_description,
    audio_url: after?.audio_url ?? during?.audio_url,
    transcript: after?.transcript ?? during?.transcript,
    notes: after?.notes ?? during?.notes ?? '',
  };

  const callParams = after?.call_params ?? during?.call_params;

  const preferMap: PreferState = {
    answered: after?.answered ? 'after' : 'during',
    customer_status: after?.customer_status ? 'after' : 'during',
    has_outstanding_questions: after?.has_outstanding_questions !== undefined ? 'after' : 'during',
    call_dropped: after?.call_dropped !== undefined ? 'after' : 'during',
    spoke: after?.spoke !== undefined ? 'after' : 'during',
    notes: 'after',
    call_params: callParams
      ? Object.keys(callParams).reduce((acc, key) => {
        acc[key] = after?.call_params ? 'after' : 'during';

        return acc;
      }, {} as Record<string, 'after' | 'during'>)
      : {},
  };

  return {
    during,
    after,
    result: editor ?? result,
    preferMap,
  };
};

export default getInitialState;
