import { useCallback, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import { CallAnnotation } from '../../../../../types';

interface UseCallAnnotationModalLogicArgs {
  annotation: CallAnnotation;
  isValid: (annotation: CallAnnotation) => boolean;
  onConfirm: () => void;
  createCallAnnotation: (annotation: CallAnnotation) => Promise<AxiosResponse<CallAnnotation>>;
  editCallAnnotation: (annotation: CallAnnotation) => Promise<AxiosResponse<CallAnnotation>>;
}

const useCallAnnotationModalLogic = ({
  annotation,
  isValid,
  onConfirm: onConfirmBase,
  createCallAnnotation,
  editCallAnnotation,
}: UseCallAnnotationModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!isValid(annotation)) {
      return;
    }

    setSaving(true);

    try {
      if (annotation.callAnnotationId) {
        await editCallAnnotation(annotation);
      } else {
        await createCallAnnotation(annotation);
      }

      onConfirmBase();
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [annotation, createCallAnnotation, editCallAnnotation, isValid, onConfirmBase]);

  return {
    saving,
    onConfirm,
  };
};

export default useCallAnnotationModalLogic;
