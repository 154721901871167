import dayjs from 'dayjs';

export const formatDate = (date?: string, withSeconds: boolean = false) => {
  if (!date) {
    return '';
  }

  return dayjs(date).format(`L ${withSeconds ? 'LTS' : 'LT'}`);
};

export default formatDate;
