import { PropsWithChildren } from 'react';

import PageLayout from '../pageLayout';
import CustomersTabs from '../customersTabs';
import CustomersProvider from '../customersProvider';
import CustomersQueueSizeProvider from '../customersQueueSizeProvider';

const AuthorizedPageLayout = ({ children }: PropsWithChildren) => (
  <CustomersProvider>
    <CustomersQueueSizeProvider>
      <PageLayout>
        <CustomersTabs />
        {children}
      </PageLayout>
    </CustomersQueueSizeProvider>
  </CustomersProvider>
);

export default AuthorizedPageLayout;
