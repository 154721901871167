import { useCallback, useState } from 'react';
import { Button, message, Space } from 'antd';
import { AxiosError } from 'axios';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import { getCallsHistory } from '../../../api/callsHistory';
import useNavigate from '../../../hooks/navigation/useNavigate';
import { customerAllOption, SortOrder } from '../../../constants';
import CustomersProvider from '../../../components/customersProvider';

const useNextCallToReview = () => {
  const { currentCustomer } = CustomersProvider.useCurrentCustomer();
  const successNotification = SuccessNotificationProvider.useSuccessNotification();
  const { navigateToCallDetails } = useNavigate();

  const [loading, setLoading] = useState(false);

  const openNextCall = useCallback(async () => {
    setLoading(true);

    try {
      const response = await getCallsHistory({
        skip: 0,
        take: 1,
        sortBy: 'callLogId',
        order: SortOrder.ascend,
        reviewStatus: 0,
        search: '',
        customer: currentCustomer === customerAllOption ? undefined : currentCustomer,
      });

      const call = response.data?.data?.[0];

      if (call) {
        navigateToCallDetails(call.callLogId);
        successNotification.api?.destroy();
      } else {
        message.open({
          content: 'No more calls to review',
          type: 'warning',
          duration: 10,
        });
      }
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({
          content: error.response?.data?.message ?? error.response?.data?.detail ?? error.message,
          type: 'error',
          duration: 10,
        });
      }
    }

    setLoading(false);
  }, [currentCustomer, navigateToCallDetails, successNotification.api]);

  const openNextCallNotification = useCallback(() => {
    successNotification.open({
      key: 'submit-review-notification',
      title: 'Review submitted successfully',
      message: 'Click button below to review next call',
      duration: 40,
      btn: (api, key) => (
        <Space>
          <Button
            type="primary"
            size="small"
            loading={loading}
            onClick={async () => {
              try {
                await openNextCall();
              } catch (error) {
                console.error(error);
              }

              api.destroy(key);
            }}
          >
            Open next call
          </Button>
        </Space>
      ),
    });
  }, [loading, openNextCall, successNotification]);

  return {
    nextCallLoading: loading,
    openNextCall,
    openNextCallNotification,
  };
};

export default useNextCallToReview;
