import { useCallback, useState } from 'react';
import { CallsHistoryFilters } from '../types';
import { getDefaultFilters } from '../utils/searchParams';

interface UseFiltersProps {
  resetPage: () => void;
}

const initialFiltersState: CallsHistoryFilters = {
  campaign: undefined,
  reviewStatus: 0,
  searchBy: 'callLogUuid',
};

const useFilters = ({ resetPage }: UseFiltersProps) => {
  const [filters, setFilters] = useState<CallsHistoryFilters>(getDefaultFilters);

  const onFiltersUpdate = useCallback((
    filterName: keyof CallsHistoryFilters,
    value: CallsHistoryFilters[keyof CallsHistoryFilters],
  ) => {
    resetPage();

    setFilters((state) => ({
      ...state,
      [filterName]: value,
    }));
  }, [resetPage]);

  const clearFilters = useCallback(() => {
    resetPage();
    setFilters(initialFiltersState);
  }, [resetPage]);

  return {
    filters,
    onFiltersUpdate,
    clearFilters,
  };
};

export default useFilters;
