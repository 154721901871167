import { useNavigate as useNavigateBase } from 'react-router-dom';
import { useCallback } from 'react';
import { getLastFiltersAndSearch, saveLastFiltersAndSearch } from '../../features/callsHistory/utils/searchParams';

const useNavigate = () => {
  const navigate = useNavigateBase();

  const navigateToSignIn = useCallback(() => navigate('/sign-in'), [navigate]);

  const navigateToMain = useCallback((withFiltersSearchParams = false) => {
    if (!withFiltersSearchParams) {
      navigate('/');
      return;
    }

    const params = getLastFiltersAndSearch();

    if (!params) {
      navigate('/');
      return;
    }

    navigate(`/?${params.toString()}`);
  }, [navigate]);

  const navigateBack = useCallback(() => navigate(-1), [navigate]);

  const navigateToCallDetails = useCallback((callId: number, saveFiltersSearchParams = false) => {
    if (saveFiltersSearchParams) {
      saveLastFiltersAndSearch();
    }

    navigate(`/call-details/${callId}`);
  }, [navigate]);

  return {
    navigateToMain,
    navigateBack,
    navigateToSignIn,
    navigateToCallDetails,
  };
};

export default useNavigate;
