import { ChangeEvent, useCallback } from 'react';
import {
  Col,
  Input,
  Row,
  Typography,
} from 'antd';

import { CallInfo } from '../../../../api/callsHistory/types';
import { OnReviewUpdateArgs } from '../../types';

interface NotesProps {
  notes?: CallInfo['created'];
  onUpdate: ({ value, propName }: OnReviewUpdateArgs) => void;
  disabled: boolean;
}

const NotesRow = ({
  notes,
  onUpdate: onUpdateBase,
  disabled,
}: NotesProps) => {
  const onUpdate = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdateBase({
      propName: 'notes',
      value: event.target.value,
    });
  }, [onUpdateBase]);

  return (
    <Row>
      <Col span={5}><Typography.Text strong>Notes</Typography.Text></Col>
      <Col span={19}>
        <Input.TextArea
          value={notes}
          onChange={onUpdate}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default NotesRow;
