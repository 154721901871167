import dayjs from 'dayjs';

export const formatDuration = (rawSeconds?: number): string => {
  if (rawSeconds === 0) {
    return '0 s';
  }

  if (!rawSeconds) {
    return '';
  }

  const duration = dayjs.duration(rawSeconds, 's');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${hours ? `${hours} h` : ''}${minutes ? ` ${minutes} min` : ''}${seconds ? ` ${seconds} s` : ''}`;
};

export default formatDuration;
